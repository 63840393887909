import { useState } from 'react';
import Button from '../Button';
import Input from '../Input';
import SocialLogin from './SocialLogin';
import { useAuth } from '@mm/common/auth/useAuth';

export default function SignIn({ changePage }) {
  const auth = useAuth();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  return (
    <>
      <h3 className="text-md font-bold leading-6 mb-6">Влез в профила си</h3>
      <form
        onSubmit={async e => {
          e.preventDefault();
          setError(null);

          try {
            await auth.signIn(email, password);
          } catch (e) {
            setError(e.message);
          }
        }}
      >
        <label className="block mb-2">
          <span className="font-medium text-sm mb-1 block">Email</span>
          <Input
            block
            onChange={e => setEmail(e.target.value)}
            placeholder="email@domain.com"
            name="email"
            required
            autoComplete="email"
          />
        </label>

        <label className="relative block">
          <span className="font-medium text-sm mb-1 block">Парола</span>
          <Input
            block
            onChange={e => setPassword(e.target.value)}
            placeholder="••••••••"
            name="password"
            type="password"
            autoComplete="current-password"
            required
          />
          <span
            className="text-gray-600 cursor-pointer text-xs absolute right-3 bottom-3"
            onClick={() => changePage('forgot')}
          >
            Забравена?
          </span>
        </label>

        <div className="flex justify-between items-center mt-3">
          <Button variant="primary">Вход</Button>
          <Button variant="link" size="sm" onClick={() => changePage('signup')}>
            Нова Регистрация
          </Button>
        </div>

        {error && (
          <p className="text-red-700 text-sm my-2">Грешен email или парола.</p>
        )}

        <hr className="my-6" />
        <SocialLogin />
      </form>
    </>
  );
}
