export const UNITS = {
  UNIT: 'unit',
  L: 'l',
  ML: 'ml',
  KG: 'kg',
  G: 'g',
  M: 'm',
  CM: 'cm',
};

export const UNKNOWN_PROMOS_END_DATE_SHOPS = new Set(['balevbio']);
