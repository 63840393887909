import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { IUser, useApi, useUser } from '@mm/common';
import * as authService from './service';

type IAuthContext = {
  isLoggedIn: boolean;
  isLoading: boolean;
  signIn: (email: string, password: string) => Promise<IUser>;
  signUp: (user: Partial<IUser>) => Promise<IUser>;
  signInFacebook: (token: string) => Promise<IUser>;
  signInGoogle: (token: string) => Promise<IUser>;
  signInApple: (credentials: {
    identityToken: string;
    fullName: { givenName: string; familyName: string; nickname: string };
  }) => Promise<IUser>;
  sendResetToken: (email: string) => Promise<void>;
  resetPassword: (token: string, password: string) => Promise<void>;
  logout: () => void;
  initials: string;
  loginModalOpen: boolean;
  setLoginModalOpen: (val: boolean) => void;
};

const AuthContext = createContext<IAuthContext | null>(null);

export function AuthProvider({ children }) {
  const api = useApi();
  const [loginModalOpen, setLoginModalOpen] = useState(false);
  const { data: user, isLoading, mutate: mutateUser } = useUser();

  const signIn = useCallback(async (email, password) => {
    const { profile, tokens } = await authService.signIn(api)({
      email,
      password,
    });
    await api.persistJWT(tokens);
    mutateUser(profile, { revalidate: false });
    return profile;
  }, []);

  const signUp = useCallback(async user => {
    const { profile, tokens } = await authService.signUp(api)(user);
    // setTokens(tokens);
    await api.persistJWT(tokens);
    mutateUser(profile, { revalidate: false });
    return profile;
  }, []);

  const logout = useCallback(async () => {
    await api.removeJWT();
    mutateUser(null, { revalidate: false });
    // window.location.reload();
  }, []);

  const signInFacebook = useCallback(async (token: string) => {
    const { profile, tokens } = await authService.signInFacebook(api)({
      token,
    });
    await api.persistJWT(tokens);
    mutateUser(profile, { revalidate: false });
    return profile;
  }, []);

  const signInGoogle = useCallback(async (token: string) => {
    const { profile, tokens } = await authService.signInGoogle(api)({
      token,
    });
    await api.persistJWT(tokens);
    mutateUser(profile, { revalidate: false });
    return profile;
  }, []);

  const signInApple = useCallback(
    async (credentials: {
      identityToken: string;
      fullName: { givenName: string; familyName: string; nickname: string };
    }) => {
      const { profile, tokens } = await authService.signInApple(api)(
        credentials
      );
      // setTokens(tokens);
      await api.persistJWT(tokens);
      mutateUser(profile, { revalidate: false });
      return profile;
    },
    []
  );

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn: !!user,
        isLoading,
        signIn,
        signUp,
        signInFacebook,
        signInGoogle,
        signInApple,
        logout,
        loginModalOpen,
        setLoginModalOpen,
        initials: useMemo(() => {
          if (!user) return '';

          const nameSplit = user.name.split(' ');
          if (nameSplit.length > 1)
            return `${nameSplit[0][0]}${nameSplit[1][0]}`;

          return nameSplit[0][0];
        }, [user]),
        sendResetToken: useCallback(async (email: string) => {
          await authService.sendResetToken(api)(email);
        }, []),
        resetPassword: useCallback(async (token: string, password: string) => {
          await authService.resetPassword(api)({
            token,
            password,
          });
        }, []),
        // tokens,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('Missing AuthProvider');
  }

  return context;
}
