import Script from 'next/script';
import { useState } from 'react';
import Modal from '../Modal';
import Forgot from './Forgot';
import SignIn from './SignIn';
import SignUp from './SignUp';
import { useAuth } from '@mm/common/auth/useAuth';

export default function AuthModal() {
  const [page, setPage] = useState('signin');
  const auth = useAuth();

  return (
    <>
      <script
        dangerouslySetInnerHTML={{
          __html: `
                window.fbAsyncInit = function() {
                    FB.init({
                    appId      : '${process.env.NEXT_PUBLIC_FB_APP_ID}',
                    cookie     : true,
                    xfbml      : true,
                    version    : 'v16.0'
                    });
                    
                    FB.AppEvents.logPageView();   
                };

                
                (function(d, s, id){
                    var js, fjs = d.getElementsByTagName(s)[0];
                    if (d.getElementById(id)) {return;}
                    js = d.createElement(s); js.id = id;
                    js.src = "https://connect.facebook.net/bg_BG/sdk.js";
                    fjs.parentNode.insertBefore(js, fjs);
                }(document, 'script', 'facebook-jssdk'));
                
        `,
        }}
      ></script>
      <Modal
        isOpen={auth.loginModalOpen && !auth.isLoggedIn}
        onClose={() => auth.setLoginModalOpen(false)}
      >
        {page === 'signin' && <SignIn changePage={setPage} />}
        {page === 'signup' && <SignUp changePage={setPage} />}
        {page === 'forgot' && <Forgot changePage={setPage} />}
      </Modal>
    </>
  );
}
