import useSWR, { useSWRConfig } from 'swr';
import { IProductVariant } from '../../product-variants/models';
import { useLocationId } from './shops';
import { useCallback, useMemo } from 'react';
import { v4 as uuid } from 'uuid';
import { useApi } from '../api/ApiProvider';
import { getProductFromId } from '../utils/utils';
import { useAuth } from '../../auth/useAuth';
import queryString from 'query-string';
import type { IUser } from './user';

export type IShoppingList = {
  id: string;
  title: string;
  items: IShoppingListItem[];
  selectedShops?: string[] | null;
  user: Pick<IUser, 'id' | 'name' | 'email'>;
  users: Pick<IUser, 'id' | 'name' | 'email'>[];
  invites: { email: string }[];
};

export type IShoppingListItem = {
  _id: string;
  customName?: string;
  quantity: number;
  isBought: boolean;
  item?: IProductVariant;
  notes: {
    text: string;
    createdAt: string;
    user?: Pick<IUser, 'id' | 'name' | 'email'>;
  }[];
  priceAlert?: boolean;
};

export type IShoppingListAggregatedItem = IShoppingListItem & {
  cheapestShopItem: IProductVariant;
  cheaperItems: IProductVariant[];
};

export const getShoppingLists = api => () =>
  api.get('/v1/shopping-lists', true);

export const getShoppingListItems =
  api =>
  (id, { locationId }) =>
    api.get(
      `/v1/shopping-lists/${id}?${queryString.stringify({ locationId })}`,
      true
    );

export const getShoppingListItemsv2 =
  api =>
  (id, { locationId }) =>
    api.get(
      `/v2/shopping-lists/${id}?${queryString.stringify({ locationId })}`,
      true
    );

export const saveShoppingList =
  api =>
  ({ id, ...params }: Partial<IShoppingList>) =>
    api.post(`/v1/shopping-lists${(id && `/${id}`) || ''}`, true, params);

export const removeShoppingList = api => id =>
  api.remove(`/v1/shopping-lists/${id}`, true);

export const addToShoppingList =
  api =>
  (productVariantId, listId, quantity = 1, customName) =>
    api.post(`/v1/shopping-lists/${listId}/items/${productVariantId}`, true, {
      quantity,
      customName,
    });

export const removeFromShoppingList =
  api =>
  (productVariantId, listId, quantity = 0) =>
    api.post(`/v1/shopping-lists/${listId}/items/${productVariantId}`, true, {
      quantity,
    });

export const addToShoppingListV2 =
  api =>
  (productVariantId, listId, quantity = 1, customName) =>
    api.post(`/v2/shopping-lists/${listId}/items/${productVariantId}`, true, {
      quantity,
      customName,
    });

export const removeFromShoppingListV2 =
  api =>
  (productVariantId, listId, quantity = 0) =>
    api.post(`/v2/shopping-lists/${listId}/items/${productVariantId}`, true, {
      quantity,
    });

export const toggleIsBought = api => (productVariantId, listId, isBought) =>
  api.post(`/v1/shopping-lists/${listId}/items/${productVariantId}`, true, {
    isBought,
  });

export const itemUpdate = api => (productVariantId, listId, params) =>
  api.post(
    `/v2/shopping-lists/${listId}/items/${productVariantId}`,
    true,
    params
  );

export const shareInvite = api => (listId, email) =>
  api.post(`/v1/shopping-lists/${listId}/share`, true, { email });

export const shareAccept = api => (listId, token) =>
  api.post(`/v1/shopping-lists/${listId}/share/${token}`, true);

export const matchListItem = (
  item?: IProductVariant,
  listItem?: IShoppingListItem
) =>
  item &&
  listItem &&
  getProductFromId(listItem._id) === getProductFromId(item.id) &&
  listItem.item?.weight === item.weight;

export function useLists() {
  const api = useApi();
  const { isLoggedIn } = useAuth();
  const { data, isLoading, mutate } = useSWR<IShoppingList[]>(
    isLoggedIn ? '/lists' : null,
    () => getShoppingLists(api)()
  );

  return {
    data,
    isLoading,
    mutate,
    isItemAdded: useCallback(
      (item?: IProductVariant) =>
        item &&
        data?.some(list =>
          list.items.some(listItem => matchListItem(item, listItem))
        ),
      [data]
    ),
    createList: useCallback(async (partialList: Partial<IShoppingList>) => {
      const res = await saveShoppingList(api)(partialList);
      mutate();
      return res as IShoppingList;
    }, []),
  };
}

export function useList(id: string) {
  const api = useApi();
  const { data: lists, isLoading, mutate } = useLists();
  const list = useMemo(() => lists?.find(list => list.id === id), [id, lists]);
  const addToList = useAddToList();
  const removeFromList = useRemoveFromList();

  return {
    data: list,
    isLoading,
    mutate,
    save: useCallback(
      async (partialList: Partial<IShoppingList>) => {
        await saveShoppingList(api)({ id, ...partialList });
        mutate();
      },
      [id]
    ),
    remove: useCallback(async () => {
      await removeShoppingList(api)(id);
      mutate();
    }, [id]),
    toggleIsBought: useCallback(
      async (listItemId: string, isBought: boolean) => {
        await itemUpdate(api)(listItemId, id, { isBought });
        mutate();
      },
      [id]
    ),
    itemUpdate: useCallback(
      async (listItemId: string, params: Partial<IShoppingListItem>) => {
        await itemUpdate(api)(listItemId, id, params);
        mutate();
      },
      [id]
    ),
    addToList: useCallback(
      async (listItemId: string, qty?: number) => {
        await addToList(listItemId, id, qty);
      },
      [id]
    ),
    addToListCustom: useCallback(
      async (name: string) => {
        await addToList(uuid(), id, 1, name);
      },
      [id]
    ),
    removeFromList: useCallback(
      async (listItemId: string, qty?: number) => {
        await removeFromList(listItemId, id, qty);
      },
      [id]
    ),
    shareInvite: useCallback(
      async (email: string) => {
        await shareInvite(api)(id, email);
        mutate();
      },
      [id]
    ),
    shareAccept: useCallback(
      async (token: string) => {
        await shareAccept(api)(id, token);
        mutate();
      },
      [id]
    ),
  };
}

export function useAddToList() {
  const api = useApi();
  const { mutate } = useSWRConfig();

  return useCallback(
    async (
      itemId: string,
      listId: string,
      qty?: number,
      customName?: string
    ) => {
      await addToShoppingListV2(api)(itemId, listId, qty, customName);

      // if (!qty)
      mutate(
        key =>
          (typeof key === 'string' && key === '/lists') ||
          (!qty &&
            Array.isArray(key) &&
            key[0] === '/list/items' &&
            key[1] === listId)
      );
    },
    []
  );
}

export function useRemoveFromList() {
  const api = useApi();
  const { mutate } = useSWRConfig();

  return useCallback(async (itemId: string, listId: string, qty?: number) => {
    await removeFromShoppingListV2(api)(itemId, listId, qty);

    // if (!qty)
    mutate(
      key =>
        (typeof key === 'string' && key === '/lists') ||
        (!qty &&
          Array.isArray(key) &&
          key[0] === '/list/items' &&
          key[1] === listId)
    );
  }, []);
}

export function useListItems(id?: string) {
  const api = useApi();
  const locationId = useLocationId();

  return useSWR<IProductVariant[]>(
    locationId && id && ['/list/items', id, locationId],
    ([, id, locationId]) => getShoppingListItemsv2(api)(id, { locationId })
  );
}
