import keyBy from 'lodash/keyBy';
import useSWRImmutable from 'swr/immutable';
import { useUser } from './user';
import { useApi } from '../api/ApiProvider';
import { defaultCity } from '../../cities/constants';
import queryString from 'query-string';

export type IShopId = 'kaufland' | 'lidl' | 'billa' | 'balevbio' | 'cba';

export type IShop = {
  id: IShopId;
  name: string;
  imgUrl: string;
  squareImgUrl: string;
};

export type IShopLocation = {
  id: string;
  shopId: IShopId;
  locationId: string;
  address: string;
  hasOfferings: boolean;
  isDefault: boolean;
};

export const getShops = api => (query?) =>
  api.get(`/v1/shops?${queryString.stringify(query)}`, true, {
    shouldFailInsecure: false,
  }) as Promise<IShop[]>;

export const getShopLocations = api => query =>
  api.get(`/v1/shops/locations?${queryString.stringify(query)}`, true, {
    shouldFailInsecure: false,
  });

export async function fetchShops(api) {
  const shops = await getShops(api)();

  return {
    shops,
    shopsEntities: keyBy(shops, 'id'),
  };
}

let shopsEntities: Record<string, IShop> = {};
export function useShops() {
  const api = useApi();
  const swr = useSWRImmutable<IShop[]>('/shops', () => getShops(api)(), {
    onSuccess: d => {
      shopsEntities = keyBy(d, 'id');
    },
  });

  return {
    ...swr,
    entities: shopsEntities,
  };
}

export function useShopsLocations(cityId: string) {
  const api = useApi();
  return useSWRImmutable<IShopLocation[]>(
    ['/shops/locations', cityId],
    ([, cityId]) => cityId && getShopLocations(api)({ cityId })
  );
}

export function useLocationId() {
  const { data: user, isLoading: userIsLoading } = useUser();
  const { data: shops } = useShops();
  const { data: shopLocations } = useShopsLocations(user?.city || defaultCity);

  if (!shops || !shopLocations) return null;

  return shops.flatMap(shop => {
    const locationsWithOfferings = shopLocations.filter(
      location => location.shopId === shop.id && location.hasOfferings
    );
    return locationsWithOfferings.length > 0
      ? locationsWithOfferings.map(location => location.locationId)
      : `default-${shop.id}`;
  });
}
