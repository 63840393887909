import { useApi } from '../api/ApiProvider';
import useSWRImmutable from 'swr/immutable';
import keyBy from 'lodash/keyBy';

export type ICity = {
  id: string;
  name: string;
  province: string;
  municipality: string;
};

let entities: Record<string, ICity> = {};

export function useCities() {
  const api = useApi();
  const swr = useSWRImmutable<ICity[]>(
    ['/cities'],
    () => api.get(`/v1/cities`),
    {
      onSuccess: d => {
        entities = keyBy(d, 'id');
      },
    }
  );

  return {
    ...swr,
    entities,
  };
}
