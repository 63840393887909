import Script from 'next/script';
import { useState } from 'react';
import Button from '../Button';
import FacebookIcon from '../icons/FacebookIcon';
// import GoogleIcon from '../icons/GoogleIcon';
import { useAuth } from '@mm/common/auth/useAuth';

export default function SocialLogin() {
  const auth = useAuth();
  const [error, setError] = useState('');

  return (
    <>
      {/* <Script src="https://connect.facebook.net/bg_BG/sdk.js" /> */}
      <Script
        src="https://accounts.google.com/gsi/client"
        onReady={() => {
          async function handleCredentialResponse(response) {
            // console.log('handleCredentialResponse', response);
            setError(null);
            try {
              await auth.signInGoogle(response.credential);
            } catch (e) {
              setError(e.message);
            }
          }
          // @ts-ignore
          const google = window.google;
          if (!google) return;

          google.accounts.id.initialize({
            client_id: process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID,
            callback: handleCredentialResponse,
          });
          google.accounts.id.renderButton(
            document.getElementById('googleBtn'),
            { theme: 'outline', size: 'large', locale: 'bg' }
          );
          // also display the One Tap dialog
          // google.accounts.id.prompt();
        }}
      />
      <div className="grid grid-cols-2 gap-3">
        <Button
          variant="outline"
          className="mb-2 text-sm pb-[10px]"
          onClick={() => {
            // @ts-ignore
            FB.getLoginStatus(function (res) {
              // console.log('FB.getLoginStatus', res);
              if (res.status === 'connected' && res.authResponse?.accessToken) {
                return auth.signInFacebook(res.authResponse?.accessToken);
              }

              // @ts-ignore
              FB.login(function (res) {
                if (
                  res.status === 'connected' &&
                  res.authResponse?.accessToken
                ) {
                  auth.signInFacebook(res.authResponse?.accessToken);
                }
              });
            });
          }}
          iconLeft={<FacebookIcon className="h-4 w-4 mr-3" fill="#1877f2" />}
        >
          Вход с Facebook
        </Button>
        <div id="googleBtn" />
        {/* <Button
        variant="outline"
        className="mb-2 text-sm w-full sm:w-3/4"
        onClick={() => {
          // @ts-ignore
        }}
        iconLeft={<GoogleIcon className="h-4 w-4 mr-3" />}
      >
        Вход с Google
      </Button> */}
        {error && <p className="text-red-700 text-sm my-2">Грешка: {error}</p>}
      </div>
    </>
  );
}
