import React, { memo } from 'react';
import { useShops } from '@mm/common';
import classNames from 'classnames';

function ShopImage({
  id,
  type,
  className,
  ...props
}: {
  id: string;
  type?: 'square';
  className?: string;
}) {
  const { entities } = useShops();
  const shop = entities[id];

  if (!shop) {
    return <span>{id}</span>;
  }

  const sizeProps =
    type === 'square'
      ? {
          width: 16,
          height: 16,
        }
      : {};

  return (
    <img
      alt={shop.name}
      src={type === 'square' ? shop.squareImgUrl || shop.imgUrl : shop.imgUrl}
      className={classNames('object-contain', className)}
      {...sizeProps}
      {...props}
    />
  );
}

export default memo(ShopImage);
