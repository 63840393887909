import NextErrorComponent, { ErrorProps } from 'next/error';
import * as Sentry from '@sentry/nextjs';
import Page from '../components/Page';
import Container from '../components/Container';
import { IImport, searchImports } from '@mm/common';
import { slugifyImport } from '@/utils/url';
import Button from '@/components/Button';
import Link from 'next/link';
import ImportCard from '@/components/ImportCard';
import api from '@/utils/api';

const MyError = (
  props: ErrorProps & {
    hasGetInitialPropsRun?: boolean;
    err?: any;
    offers?: IImport[];
  }
) => {
  // console.log('MyErrorProps', props);
  let { statusCode, hasGetInitialPropsRun, err, offers } = props;

  statusCode = err?.code || statusCode;

  if (!hasGetInitialPropsRun && err && statusCode !== 404) {
    // getInitialProps is not called in case of
    // https://github.com/vercel/next.js/issues/8592. As a workaround, we pass
    // err via _app.js so it can be captured
    const error = new Error(err.message);
    if (err.name) {
      error.name = err.name;
    }
    Sentry.captureException(error);
    // Flushing is not required in this case as it only happens on the client
  }

  return (
    <Page title={props.title}>
      <Container>
        <div className="my-6 px-16">
          <h1 className="text-xl text-center font-semibold my-3">
            Грешка {statusCode}
          </h1>
          <h2 className="text-center">
            {statusCode === 404 ? 'Страницата не беше намерена' : err?.message}
          </h2>

          {/* Promos */}
          {offers?.length > 0 && (
            <>
              <div className="mt-6">
                <div className="flex justify-between">
                  <h2 className="text-xl font-bold my-8">Актуални Промоции</h2>
                  <Button
                    as={Link}
                    size="sm"
                    variant="link"
                    href="/shop?page=1"
                  >
                    Виж Още
                  </Button>
                </div>
                <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-1">
                  {offers.map(d => (
                    <ImportCard
                      as={Link}
                      key={d.id}
                      href={slugifyImport(d)}
                      item={d}
                    />
                  ))}
                </div>
              </div>
              <div className="flex justify-center my-6">
                <Button
                  variant="primary"
                  as={Link}
                  href="/shop?page=1"
                  size="lg"
                >
                  Виж още
                </Button>
              </div>
            </>
          )}
        </div>
      </Container>
    </Page>
  );
};

MyError.getInitialProps = async context => {
  const errorInitialProps = await NextErrorComponent.getInitialProps(context);

  const { err, asPath } = context;
  const statusCode = err?.code || errorInitialProps.statusCode;

  // Workaround for https://github.com/vercel/next.js/issues/8592, mark when
  // getInitialProps has run
  // @ts-ignore
  errorInitialProps.hasGetInitialPropsRun = true;

  // Returning early because we don't want to log 404 errors to Sentry.
  if (statusCode === 404) {
    // @ts-ignore
    const { docs } = await searchImports(api)({
      dealsOnly: true,
      // name: query.query || item.name,
      limit: 12,
    });
    errorInitialProps.offers = docs;
    return errorInitialProps;
  }

  // Running on the server, the response object (`res`) is available.
  //
  // Next.js will pass an err on the server if a page's data fetching methods
  // threw or returned a Promise that rejected
  //
  // Running on the client (browser), Next.js will provide an err if:
  //
  //  - a page's `getInitialProps` threw or returned a Promise that rejected
  //  - an exception was thrown somewhere in the React lifecycle (render,
  //    componentDidMount, etc) that was caught by Next.js's React Error
  //    Boundary. Read more about what types of exceptions are caught by Error
  //    Boundaries: https://reactjs.org/docs/error-boundaries.html

  if (err) {
    Sentry.captureException(err);

    // Flushing before returning is necessary if deploying to Vercel, see
    // https://vercel.com/docs/platform/limits#streaming-responses
    await Sentry.flush(2000);

    return errorInitialProps;
  }

  // If this point is reached, getInitialProps was called without any
  // information about what the error might be. This is unexpected and may
  // indicate a bug introduced in Next.js, so record it in Sentry
  Sentry.captureException(
    new Error(`_error.js getInitialProps missing data at path: ${asPath}`)
  );
  await Sentry.flush(2000);

  return errorInitialProps;
};

export default MyError;
