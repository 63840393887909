import clsx from 'classnames';
import {
  ChangeEventHandler,
  forwardRef,
  InputHTMLAttributes,
  RefObject,
} from 'react';

type InputProps = InputHTMLAttributes<HTMLInputElement> & {
  placeholder?: string;
  className?: string;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  value?: string;
  error?: string;
  size?: 'sm' | 'md' | 'lg';
  block?: boolean;
  defaultValue?: any;
  forwardedRef?: any;
  onClick?: () => void;
};

function Input({
  className,
  size,
  block,
  error,
  forwardedRef,
  ...props
}: InputProps) {
  return (
    <input
      ref={forwardedRef as RefObject<HTMLInputElement>}
      className={clsx(
        // 'px-3 py-2 text-sm transition-all',
        'bg-gray-50 focus:bg-white border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-secondary-500 block w-full p-2.5',
        'ring-primary-500 focus:ring-1 outline-none',
        {
          'py-0.5': size === 'sm',
          'block w-full': block === true,
          '!border-red-400 hover:!border-red-500 focus:!border-red-500 focus:!ring-red-400':
            !!error,
        },
        className
      )}
      placeholder="Search..."
      title={error}
      {...props}
    />
  );
}

export default forwardRef((props: InputProps, ref) => (
  <Input {...props} forwardedRef={ref} />
));
