import '../styles/globals.scss';
import 'react-loading-skeleton/dist/skeleton.css';
import 'react-toastify/dist/ReactToastify.css';

import Error from './_error';
import { AppProps } from 'next/app';
import { AuthProvider } from '@mm/common/auth/useAuth';
import { ToastContainer } from 'react-toastify';
import Head from 'next/head';
import { ApiProvider } from '@mm/common';
import nodeFetch from 'node-fetch';
import { GoogleTagManager, GoogleAnalytics } from '@next/third-parties/google';

function MyApp({ Component, pageProps }: AppProps) {
  if (pageProps.err) {
    return <Error {...pageProps} />;
  }
  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1"
        />
        <script
          async
          src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-4775325120483530"
          crossOrigin="anonymous"
        ></script>
      </Head>
      <ApiProvider
        baseUrl={process.env.NEXT_PUBLIC_API_URL}
        storage={
          typeof window !== 'undefined' ? window.localStorage : undefined
        }
        fetch={typeof window !== 'undefined' ? window.fetch : nodeFetch}
      >
        <AuthProvider>
          <Component {...pageProps} />
          <ToastContainer />
          <GoogleTagManager gtmId="GTM-W8FJK9KG" />
          <GoogleAnalytics gaId="G-E0JF71ZCDN" />
        </AuthProvider>
      </ApiProvider>
    </>
  );
}

export default MyApp;
