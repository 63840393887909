import { IOffering } from '../../product-variants/models';
import { IPaginatedRes, PaginateQuery } from '../utils/types';
import qs from 'query-string';
import { IApi } from '../api/api';
import { UNITS } from '../../product-variants/constants';
import { IShopId, useLocationId } from './shops';
import { useApi } from '../api/ApiProvider';
import useSWR from 'swr';

export type IImport = {
  id: string;
  shop: IShopId;
  // slug: string;
  name: string;
  nameLat: string;
  brand?: string;
  brandLat?: string;
  description?: string[];
  thumbnail?: string;
  imghash?: string;
  lastUpdated: string | number;
  lastScanned?: string | number;
  variants?: string[];
  category: string;
  categoryName: string;
  weight?: number;
  unit?: (typeof UNITS)[keyof typeof UNITS];
  quantityPerUnit?: number;
  offerings: IOffering[];
  prevOfferings: IOffering[];
  vendorId?: string;
  url?: string;
  // imgUrl?: string;
  pinned?: boolean;
};

export type IScan = {
  id: string;
  shop: IShopId;
  name: string;
  brand?: string;
  description?: string[];
  thumbnail?: string;
  imghash?: string;
  lastUpdated: string | number;
  lastScanned?: string | number;
  variants?: string[];
  category: string;
  weight?: number;
  unit?: (typeof UNITS)[keyof typeof UNITS];
  quantityPerUnit?: number;
  offerings: IOffering[];
  prevOfferings: IOffering[];
  vendorId?: string;
  url?: string;
  importWarnings: string[];
  matches: {
    import: string;
    lev: number;
  }[];
};

export type RawImport = {
  source: 'glovo';
  shop: IShopId;
  name: string;
  nameLat: string;
  brand?: string;
  brandLat?: string;
  description?: string[];
  thumbnail?: string;
  imghash?: string;
  offering: IOffering;
  offerings?: IOffering[];
  vendorId?: string;
  weight?: number;
  unit?: (typeof UNITS)[keyof typeof UNITS];
  quantityPerUnit?: number;
  url?: string;
};

export type ImportsSearchQuery = PaginateQuery<{
  name?: string;
  dealsOnly?: boolean | 'true' | 'false';
  shop?: IShopId[];
  category?: string;
  not?: string; // exclude id
  pinned?: boolean | 'true' | 'false';
  pinnedOnly?: boolean | 'true' | 'false';
  random?: boolean | 'true' | 'false';
  sort?: string[];
}>;

export const searchImports =
  (api: IApi) =>
  (filters: ImportsSearchQuery = {}) =>
    api.get<IPaginatedRes<IImport>>(
      `/v1/imports/search?${qs.stringify(filters)}`
    );
export function useSearchImports(filters: ImportsSearchQuery = {}) {
  const api = useApi();
  // const locationId = useLocationId();

  return useSWR(
    ['/imports/search', filters],
    ([, filters]) => filters.name && searchImports(api)(filters)
  );
}

export const getImport = (api: IApi) => (id: string) =>
  api.get<IImport>(`/v1/imports/${id}`);

export const getImportFromVariantId = (api: IApi) => (id: string) =>
  api.get<IImport>(`/v1/imports/variant/${id}`);

export function useGetImport(id: string) {
  const api = useApi();
  // const locationId = useLocationId();

  return useSWR(['/imports', id], ([, id]) => getImport(api)(id));
}
