import { ICategory } from '../../categories/models';
import queryString from 'query-string';
import { useApi } from '../api/ApiProvider';
import useSWRImmutable from 'swr/immutable';
import keyBy from 'lodash/keyBy';

export const fetchCategories = api =>
  async function fetchCategories({ tree = false, depth = 0 } = {}) {
    const categories = (await api.get(
      `/v1/categories?${queryString.stringify({ tree, depth })}`
    )) as ICategory[];
    //
    return {
      categories,
    };
  };

let entities: Record<string, ICategory> = {};

export function useCategories() {
  const api = useApi();
  const swr = useSWRImmutable<ICategory[]>(
    ['/categories'],
    () => api.get(`/v1/categories`),
    {
      onSuccess: d => {
        entities = keyBy(d, 'id');
      },
    }
  );

  return {
    ...swr,
    entities: entities,
  };
}
