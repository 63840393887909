import slugify from 'slugify';
import { IImport } from '@mm/common';

export const slugifyImportName = (d: IImport) =>
  slugify([d.brand, d.name].filter(Boolean).join('-'), {
    strict: true,
    lower: true,
  });

export const slugifyImport = (d: IImport) =>
  `/${d.categoryName
    .split('/')
    .slice(0, 2)
    .map(cat => slugify(cat, { lower: true }))
    .join('/')}/${slugifyImportName(d)}/${d.shop}/${d.id}`;
