import { useState } from 'react';
import Button from '../Button';
import Input from '../Input';
import { ArrowLongLeftIcon } from '@heroicons/react/24/outline';
import { useAuth } from '@mm/common/auth/useAuth';
import { toast } from 'react-toastify';

export default function Forgot({ changePage }) {
  const auth = useAuth();
  const [email, setEmail] = useState('');
  const [autocompleteEmail, setAutocompleteEmail] = useState('');
  const [password, setPassword] = useState('');
  const [password2, setPassword2] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [showCode, setShowCode] = useState(false);
  const [code, setCode] = useState('');

  return (
    <>
      <div className="flex">
        <ArrowLongLeftIcon
          className="h-6 cursor-pointer hover:stroke-secondary-600 pr-3"
          onClick={() => changePage('signin')}
        />
        <h3 className="text-md font-bold mb-6">Забравена парола</h3>
      </div>
      <form
        onSubmit={async e => {
          e.preventDefault();
          setError(null);

          try {
            await auth.sendResetToken(email);
            setAutocompleteEmail(email);
            setEmail('');
            setSuccess(`На ${email} беше изпратен код за промяна на парола.`);
          } catch (e) {
            setError(e.message);
          }
        }}
      >
        <label className="block mb-2">
          <span className="font-medium text-sm mb-1 block">Email</span>
          <Input
            block
            onChange={e => setEmail(e.target.value)}
            placeholder="email@domain.com"
            name="email"
            required
            autoComplete="email"
            value={email}
          />
        </label>

        <Button disabled={!email}>Изпрати</Button>

        {success && <p className="text-green-700 text-sm my-2">{success}</p>}
        {error && !showCode && (
          <p className="text-red-700 text-sm my-2">{error}</p>
        )}
      </form>

      <hr className="my-6" />

      <Button
        variant="secondary"
        onClick={() => setShowCode(!showCode)}
        className="mb-6"
        size="sm"
      >
        Въведи код за промяна на парола
      </Button>

      {showCode && (
        <form
          onSubmit={async e => {
            e.preventDefault();
            setError(null);

            try {
              await auth.resetPassword(code, password);
              toast('Паролата беше сменена успешно.', {
                position: 'bottom-center',
              });
              changePage('signin');
            } catch (e) {
              setError('Грешен или изтекъл код за промяна на парола.');
            }
          }}
        >
          <label className="relative block mb-2">
            <span className="font-medium text-sm mb-1 block">
              Код за промяна на парола
            </span>
            <Input
              block
              value={code}
              onChange={e => setCode(e.target.value)}
              placeholder="••••••"
              name="code"
              required
              autoComplete="off"
            />
            <input
              type="hidden"
              name="email"
              autoComplete="email"
              value={autocompleteEmail}
            />
          </label>

          <label className="relative block mb-2">
            <span className="font-medium text-sm mb-1 block">Нова Парола</span>
            <Input
              block
              onChange={e => setPassword(e.target.value)}
              placeholder="••••••••"
              name="password"
              type="password"
              autoComplete="new-password"
              required
            />
          </label>

          <label className="relative block mb-2">
            <span className="font-medium text-sm mb-1 block">
              Повтори Нова Парола
            </span>
            <Input
              block
              onChange={e => setPassword2(e.target.value)}
              placeholder="••••••••"
              name="password"
              type="password"
              autoComplete="new-password"
              required
            />
          </label>

          <Button disabled={password !== password2 || !code}>
            Смени парола
          </Button>

          {error && <p className="text-red-700 text-sm my-2">{error}</p>}
        </form>
      )}
    </>
  );
}
