export const signUp = api => params =>
  api.post(`/v1/auth/sign-up`, false, params);

export const signIn = api => params =>
  api.post(`/v1/auth/login`, false, params);

export const signInFacebook = api => params =>
  api.post(`/v1/auth/login/facebook`, false, params);

export const signInGoogle = api => params =>
  api.post(`/v1/auth/login/google`, false, params);

export const signInApple = api => params =>
  api.post(`/v1/auth/login/apple`, false, params);

export const sendResetToken = api => email =>
  api.post('/v1/auth/forgot', false, { email });

export const resetPassword =
  api =>
  ({ token, password }) =>
    api.post(`/v1/auth/forgot/reset`, false, { token, password });

export const verifyEmailToken = api => token =>
  api.post(`/v1/auth/verify/${token}`, false);
