import { useCallback } from 'react';
import useSWRImmutable from 'swr/immutable';
import { useApi } from '../api/ApiProvider';
import queryString from 'query-string';

export type IUser = {
  id: string;
  email: string;
  name: string;
  city: string;
  password: string;
  // favorites: string[];
};

export const getUser = api => () => api.get('/v1/user', true);

export const saveUser = api => params => api.post('/v1/user', true, params);

export const getFavorites =
  api =>
  ({ locationId }) =>
    api.get(
      `/v1/user/favorites?${queryString.stringify({ locationId })}`,
      true
    );

export const addFavorite = api => id =>
  api.post(`/v1/user/favorite/${id}`, true);

export const removeFavorite = api => id =>
  api.remove(`/v1/user/favorite/${id}`, true);

export function useUser() {
  const api = useApi();
  const { data, isLoading, mutate } = useSWRImmutable<IUser>(
    '/user',
    getUser(api),
    {
      shouldRetryOnError: false,
    }
  );

  return {
    data,
    isLoading,
    mutate,
    updateUser: useCallback(
      async (newUser: IUser & { pushToken?: string }) => {
        await saveUser(api)(newUser);
        mutate(newUser, { revalidate: false });
      },
      [mutate]
    ),
  };
  // return useSWR<IUser>('/user', getUser(api));
}
