import cx from 'classnames';
import { ReactElement } from 'react';

export default function Container({
  children,
  className,
  as,
  ...props
}: {
  children?: ReactElement | ReactElement[];
  className?: string;
  as?: string;
}) {
  const Component = as || 'main';

  return (
    // @ts-ignore
    <Component
      className={cx(
        'container mx-auto p-4',
        {
          'min-h-[calc(100vh-220px)]': Component === 'main',
        },
        className
      )}
      {...props}
    >
      {children}
    </Component>
  );
}
