import ShopImage from './ShopImage';
import {
  getPriceDiscountPercentage,
  isExpiredOffering,
} from '@mm/common/product-variants/utils';
import * as i18n from '@mm/common/i18n';
import { UNKNOWN_PROMOS_END_DATE_SHOPS } from '@mm/common/product-variants/constants';
import dayjs from 'dayjs';
import { memo } from 'react';
import classNames from 'classnames';
import Skeleton from 'react-loading-skeleton';
// import AddToList from './AddToList';
// import { CheckCircleIcon } from '@heroicons/react/24/solid';
// import { PlusCircleIcon } from '@heroicons/react/24/outline';
import { IImport } from '@mm/common';
import { getImportImgUrl } from '@/utils/images';
import { StarIcon } from '@heroicons/react/24/solid';

export function ItemBandeau({
  children,
  position = 'tl',
  className,
  textClassName = 'text-[10px]',
  bgClassName = 'bg-stone-50',
}: {
  children: any;
  position?: 'tl' | 'tr' | 'br' | 'bl';
  className?: string;
  textClassName?: string;
  bgClassName?: string;
}) {
  return (
    <h4
      className={classNames(
        'font-semibold absolute border border-stone-100 py-1 px-2',
        {
          'top-0 left-0 rounded-tr-full rounded-br-full': position === 'tl',
          'bottom-0 left-0 rounded-tr-full rounded-br-full': position === 'bl',
          'top-0 right-0 rounded-tl-full rounded-bl-full': position === 'tr',
          'bottom-0 right-0 rounded-tl-full rounded-bl-full': position === 'br',
        },
        className,
        bgClassName,
        textClassName
      )}
    >
      {children}
    </h4>
  );
}

function ImportCard({
  item,
  as,
  className,
  loading,
  ...props
}: {
  item: IImport;
  as?: any;
  className?: string;
  loading?: boolean;
  [key: string]: any;
}) {
  const offering = item.offerings[0];
  const specialPriceEnd =
    offering.specialPrice &&
    !UNKNOWN_PROMOS_END_DATE_SHOPS.has(item.shop) &&
    dayjs(offering.specialPriceEnd).format('DD/MM');

  const Component = as || 'div';

  const discount = getPriceDiscountPercentage(
    offering.price,
    offering.specialPrice
  );
  const isExpired = isExpiredOffering(offering);

  return (
    <Component
      className={classNames(
        'bg-white p-3 border border-stone-200 hover:border-stone-300 hover:shadow relative',
        {
          'pointer-events-none': loading,
          'opacity-60': isExpired,
        },
        className
      )}
      {...props}
    >
      {item.pinned && (
        <StarIcon className="absolute w-4 h-4 fill-secondary-700" />
      )}
      <div className="h-36 flex justify-center">
        {loading ? (
          <Skeleton className="h-36" />
        ) : (
          <img
            src={getImportImgUrl(item)}
            alt={item.name}
            className={classNames('object-contain', {
              'opacity-70': isExpired,
            })}
          />
        )}
      </div>
      {loading ? (
        <>
          <div className="w-10 mt-3">
            <Skeleton />
          </div>
          <Skeleton count={2} className="mt-3 w-3" />
          <div className="w-10 mt-3">
            <Skeleton />
          </div>
        </>
      ) : (
        <>
          {item.weight ? (
            <span className="bg-violet-100 font-bold inline-block text-[11px] px-1 rounded-full my-3">
              {i18n.weightUnit({ ...item, offering })}
            </span>
          ) : (
            <div className="my-3" />
          )}
          {item.brand && (
            <h3 className="text-sm font-semibold">{item.brand}</h3>
          )}
          <h2 className="text-sm leading-5 line-clamp-2" title={item.name}>
            {item.name}
          </h2>
          <div className="h-3 mt-3 flex relative">
            <ShopImage id={item.shop} />
          </div>
          <h4 className="font-semibold text-md mt-1">
            {i18n.t('price', {
              price: offering.specialPrice || offering.price,
            })}
          </h4>
          {offering.specialPrice &&
            offering.specialPrice !== offering.price && (
              <h4 className="font-semibold text-xs line-through">
                {i18n.t('price', { price: offering.price })}
              </h4>
            )}
          {specialPriceEnd ? (
            <ItemBandeau className="top-1">до {specialPriceEnd}</ItemBandeau>
          ) : item.lastScanned ? (
            <ItemBandeau className="top-1">
              от {dayjs(item.lastScanned).format('DD/MM')}
            </ItemBandeau>
          ) : null}
          {discount < 0 && (
            <ItemBandeau
              bgClassName={classNames('top-1', {
                'bg-red-100': discount <= -50,
                'bg-amber-100': discount > -50 && discount <= -30,
                'bg-lime-50': discount > -30,
              })}
              position="tr"
            >
              {i18n.toPercentage(discount)}
            </ItemBandeau>
          )}
          {isExpired && (
            <ItemBandeau bgClassName="bg-red-100" className="top-8">
              изтекла
            </ItemBandeau>
          )}
          {/* <div className="absolute top-0 right-0">
            <AddToList
              item={item}
              addedEl={
                <CheckCircleIcon className="h-7 inline-block p-1 fill-green-700 hover:bg-stone-50 hover:shadow" />
              }
              notAddedEl={
                <PlusCircleIcon className="h-7 inline-block p-1 hover:bg-stone-50 hover:shadow" />
              }
            />
          </div> */}
        </>
      )}
    </Component>
  );
}

export default memo(ImportCard);
