import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
dayjs.extend(isSameOrBefore);
import isBetween from 'dayjs/plugin/isBetween';
dayjs.extend(isBetween);
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
dayjs.extend(isSameOrAfter);
import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat);
import relativeTime from 'dayjs/plugin/relativeTime';
dayjs.extend(relativeTime);
import updateLocale from 'dayjs/plugin/updateLocale';
dayjs.extend(updateLocale);

import 'dayjs/locale/bg';
dayjs.locale('bg');

dayjs.updateLocale('bg', {
  relativeTime: {
    future: 'след %s',
    past: 'преди %s',
    s: 'секунди',
    m: 'минута',
    mm: '%d минути',
    h: 'час',
    hh: '%d часа',
    d: 'ден',
    dd: '%d дни',
    M: 'месец',
    MM: '%d месеца',
    y: 'година',
    yy: '%d години',
  },
});

export default dayjs;
