import queryString from 'query-string';

export const getProductVariants = api => query =>
  api.get(`/v1/product-variants?${queryString.stringify(query)}`);

export const getProductVariant = api => (id, query) =>
  api.get(`/v1/product-variants/${id}?${queryString.stringify(query)}`);

export const getProductVariantRelated = api => (id, query) =>
  api.get(`/v1/product-variants/${id}/related?${queryString.stringify(query)}`);

export const getCheapestProductVariants =
  api =>
  ({ productIds, locationId }) =>
    api.get(
      `/v1/product-variants/cheapest?${queryString.stringify({
        pid: productIds,
        locationId,
      })}`
    );
