import { IProductVariant } from '../../product-variants/models';
import {
  getProductVariants,
  getProductVariant,
  getProductVariantRelated,
} from '../../product-variants/service';
import { IPaginatedRes } from '../utils/types';
import queryString from 'query-string';
import { useApi } from '../api/ApiProvider';
import useSWR from 'swr';
import { useLocationId } from './shops';

export const fetchProductVariants = api =>
  async function fetchProductVariants(filters = {}) {
    // console.log(
    //   `Fetching /v1/product-variants?${queryString.stringify(filters)}`
    // );
    const productVariants = (await api.get(
      `/v1/product-variants?${queryString.stringify(filters)}`
    )) as IPaginatedRes<IProductVariant>;

    return {
      productVariants,
    };
  };

export function useProductVariants(
  query: {
    dealsOnly?: boolean;
    name?: string;
    category?: string;
    shop?: string;
  },
  props
) {
  const api = useApi();
  const locationId = useLocationId();

  const { data, isLoading, mutate } = useSWR<IPaginatedRes<IProductVariant>>(
    ['/product-variants', locationId, query],
    ([, locationId, query]) =>
      locationId && getProductVariants(api)({ ...query, locationId }),
    props
  );

  return {
    data,
    isLoading,
    mutate,
  };
}

export const fetchProductVariantDetails = api =>
  async function fetchProductVariantDetails(
    id: string,
    query?: Record<string, any>
  ) {
    // console.log(
    //   `Fetching /v1/product-variants?${queryString.stringify(filters)}`
    // );
    const productVariants = (await api.get(
      `/v1/product-variants/${id}?${queryString.stringify(query)}`
    )) as IProductVariant[];

    return {
      productVariants,
    };
  };

export function useProductVariantDetails(id: string) {
  const api = useApi();
  const locationId = useLocationId();

  const query = { locationId };

  const { data, isLoading, mutate } = useSWR<IProductVariant[]>(
    ['/product-variants', id, query],
    ([, id, query]) => getProductVariant(api)(id, query)
  );

  return {
    data,
    isLoading,
    mutate,
  };
}

export const fetchProductVariantRelated = api =>
  async function fetchProductVariantRelated(
    id: string,
    query?: Record<string, any>
  ) {
    // console.log(
    //   `Fetching /v1/product-variants?${queryString.stringify(filters)}`
    // );
    const productVariants = (await api.get(
      `/v1/product-variants/${id}/related?${queryString.stringify(query)}`
    )) as IProductVariant[];

    return {
      productVariants,
    };
  };

export function useProductVariantRelated(id: string) {
  const api = useApi();
  const locationId = useLocationId();

  const query = { locationId };

  const { data, isLoading, mutate } = useSWR<IProductVariant[]>(
    ['/product-variants/related', id, query],
    ([, id, query]) => getProductVariantRelated(api)(id, query)
  );

  return {
    data,
    isLoading,
    mutate,
  };
}
