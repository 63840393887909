import Head from 'next/head';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { ReactElement, useState, Fragment, useEffect } from 'react';
import Container from './Container';
import Image from 'next/image';
import classNames from 'classnames';
import styles from './Page.module.scss';
import AuthModal from './auth/AuthModal';
import Input from './Input';
import { AuthProvider, useAuth } from '@mm/common/auth/useAuth';
import {
  ArrowLeftOnRectangleIcon,
  ChevronDownIcon,
  UserIcon as UserIconSmall,
} from '@heroicons/react/20/solid';
import { HeartIcon, UserCircleIcon } from '@heroicons/react/24/outline';
import { Menu, Transition } from '@headlessui/react';
import { ApiProvider, useLists } from '@mm/common';
import nodeFetch from 'node-fetch';
import queryString from 'query-string';

export default props => (
  <ApiProvider
    baseUrl={process.env.NEXT_PUBLIC_API_URL}
    storage={typeof window !== 'undefined' ? window.localStorage : undefined}
    fetch={typeof window !== 'undefined' ? window.fetch : nodeFetch}
  >
    <AuthProvider>
      <Page {...props} />
    </AuthProvider>
  </ApiProvider>
);

// export default function Page({
function Page({
  title,
  head,
  searchValue,
  onSearchSubmit,
  children,
}: {
  title?: string;
  head?: ReactElement;
  children: ReactElement | ReactElement[];
  searchValue?: string;
  onSearchSubmit?: (value: string) => void;
}) {
  const router = useRouter();
  const [search, setSearch] = useState(searchValue || '');
  const auth = useAuth();
  // const { data: lists } = useLists();

  const [top, setTop] = useState<boolean>(true);

  // detect whether user has scrolled the page down by 10px
  const scrollHandler = () => {
    window.pageYOffset > 10 ? setTop(false) : setTop(true);
  };

  useEffect(() => {
    scrollHandler();
    window.addEventListener('scroll', scrollHandler);
    return () => window.removeEventListener('scroll', scrollHandler);
  }, [top]);

  const UserMenu = (
    <>
      {/* <div
        className="relative cursor-pointer"
        onClick={() => {
          if (!auth.isLoggedIn) {
            return auth.setLoginModalOpen(true);
          }
          router.push('/lists');
        }}
      >
        <HeartIcon className="h-8" />
        {auth.isLoggedIn && (
          <span className="absolute top-0 left-0 text-xs font-bold px-1 bg-gray-900/60 rounded-full text-white">
            {lists?.flatMap(d => d.items.filter(({ item }) => !!item)).length}
          </span>
        )}
      </div> */}

      {auth.isLoggedIn ? (
        <Menu as="div" className="relative inline-block text-left">
          <Menu.Button className="flex justify-center items-center">
            <UserCircleIcon className="h-8 peer stroke-gray-800" />
            <span className="text-gray-700 peer text-sm font-semibold">
              {auth.initials}
            </span>
            <ChevronDownIcon
              className="h-5 w-5 text-stone-400 hover:text-stone-600 peer-hover:text-stone-600"
              aria-hidden="true"
            />
          </Menu.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute right-0 mt-2 w-48 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-10">
              <div className="px-1 py-1">
                {[
                  {
                    href: '/account',
                    label: 'Профил',
                    icon: (
                      <UserIconSmall
                        className="mr-2 h-5 w-5"
                        aria-hidden="true"
                      />
                    ),
                  },
                ].map(link => (
                  /* Use the `active` state to conditionally style the active item. */
                  <Menu.Item key={link.href} as={Fragment}>
                    {({ active }) => (
                      <Link
                        href={link.href}
                        className={`${
                          active
                            ? 'bg-stone-200 text-gray-900'
                            : 'text-gray-900'
                        } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                      >
                        {link.icon}
                        {link.label}
                      </Link>
                    )}
                  </Menu.Item>
                ))}
              </div>
              <div className="px-1 py-1">
                <Menu.Item>
                  {({ active }) => (
                    <button
                      className={`${
                        active ? 'bg-stone-200 text-gray-900' : 'text-gray-900'
                      } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                      onClick={() => auth.logout()}
                    >
                      <ArrowLeftOnRectangleIcon
                        className="mr-2 h-5 w-5"
                        aria-hidden="true"
                      />
                      Изход
                    </button>
                  )}
                </Menu.Item>
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
      ) : (
        <UserCircleIcon
          className="h-8 cursor-pointer"
          onClick={() => auth.setLoginModalOpen(true)}
        />
      )}
    </>
  );

  return (
    <>
      <Head>
        <title>{[title, 'MySupermarket'].filter(Boolean).join(' - ')}</title>
        <link rel="icon" href="/favicon.png" />
        {head}
      </Head>

      <div
        className={classNames(
          'w-full z-30 transition duration-300 ease-in-out bg-white md:bg-opacity-80 md:fixed',
          {
            'backdrop-blur-sm shadow-lg': !top,
          }
        )}
      >
        <Container
          as="header"
          className="flex flex-col sm:flex-row gap-4 sm:gap-14"
        >
          <div className="flex justify-between items-center mx-auto w-full sm:w-auto">
            <Link href="/" className="flex items-center hover:no-underline">
              <img src="/circle-x512.png" width={48} height={48} />
              <span className="text-2xl font-semibold ml-1 text-transparent bg-clip-text bg-gradient-to-r from-primary-500 to-secondary-500">
                MySupermarket
              </span>
            </Link>
            <div className="sm:hidden flex items-center gap-2">{UserMenu}</div>
          </div>

          <div className="flex-1 relative">
            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
              <svg
                aria-hidden="true"
                className="w-5 h-5 text-gray-500 dark:text-gray-400"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                ></path>
              </svg>
            </div>
            <Input
              block
              className="peer p-2 sm:p-3 !pl-10"
              type="search"
              placeholder="Търси продукт или марка..."
              value={search}
              onChange={e => {
                const value = e.currentTarget.value;
                setSearch(value);

                if (value === '') {
                  if (onSearchSubmit) {
                    return onSearchSubmit(value);
                  }
                  router.push(`/shop`);
                }
              }}
              onKeyDown={e => {
                const value = e.currentTarget.value;

                if (e.key === 'Enter') {
                  if (onSearchSubmit) {
                    return onSearchSubmit(value);
                  }
                  router.push(
                    `/shop?${queryString.stringify({ query: value })}`
                  );
                }
              }}
            />
          </div>

          <div className="w-32 hidden lg:block" />

          <div className="hidden sm:flex items-center gap-2">{UserMenu}</div>
        </Container>
      </div>

      <div className="md:mt-24">{children}</div>

      <Container
        as="footer"
        className="block sm:mt-6 sm:mb-4 bg-white rounded-lg shadow md:flex md:items-center md:justify-between md:p-6"
      >
        <div className="flex flex-col">
          <span className="text-sm text-gray-400">
            {new Date().getFullYear()}{' '}
            <a href="https://mysupermarket.bg/">MySupermarket BG</a>
          </span>
          <ul className="flex flex-wrap items-center gap-x-3 text-sm mt-1">
            <li>
              <Link href="/about">За нас</Link>
            </li>
            <li>
              <Link href="/terms">Общи условия</Link>
            </li>
            <li>
              <Link href="/privacy">Политика за поверителност</Link>
            </li>
            <li>
              <Link href="/contacts">Контакти</Link>
            </li>
          </ul>
        </div>
        <div className="flex flex-row gap-2 mt-4 sm:mt-0">
          <a href="https://apps.apple.com/app/id1548694466" target="_blank">
            <Image
              src={require('../public/bg-btn-apple-store.png')}
              alt="Изтегли от Apple App Store"
              height={35}
            />
          </a>
          <a
            href="https://play.google.com/store/apps/details?id=bg.mysupermarket.app"
            target="_blank"
          >
            <Image
              src={require('../public/bg-btn-google-play.png')}
              alt="Изтегли от Google Play"
              height={35}
            />
          </a>
          {/* <a
          href="https://appgallery.huawei.com/#/app/C104082087?locale=bg_BG&source=appshare&subsource=C104082087"
          target="_blank"
        >
          <img
            src="/bg-btn-appgallery.png"
            alt="Изтегли от App Gallery"
            height={50}
          />
        </a> */}
        </div>
      </Container>
      {/* <GradientBackground
        variant="large"
        className="fixed top-20 opacity-40 dark:opacity-60"
      />
      <GradientBackground
        variant="small"
        className="absolute bottom-0 opacity-20 dark:opacity-10"
      /> */}
      <AuthModal />
    </>
  );
}

export function GradientBackground({ variant, className }) {
  const classes = classNames(
    {
      [styles.colorBackground]: variant === 'large',
      [styles.colorBackgroundBottom]: variant === 'small',
    },
    className
  );

  return <div className={classes} />;
}
