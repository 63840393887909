import { ArrowLongLeftIcon } from '@heroicons/react/24/outline';
import { useState } from 'react';
import Button from '../Button';
import Input from '../Input';
import SocialLogin from './SocialLogin';
import { useAuth } from '@mm/common/auth/useAuth';
import { defaultCity } from '@mm/common/cities/constants';

export default function SignUp({ changePage }) {
  const auth = useAuth();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [password2, setPassword2] = useState('');
  const [error, setError] = useState<any>();

  return (
    <>
      <div className="flex">
        <ArrowLongLeftIcon
          className="h-6 cursor-pointer hover:stroke-secondary-600 pr-3"
          onClick={() => changePage('signin')}
        />
        <h3 className="text-md font-bold mb-6">Регистрация</h3>
      </div>
      <form
        onSubmit={async e => {
          e.preventDefault();
          setError(null);

          try {
            await auth.signUp({
              name,
              email,
              city: defaultCity,
              password,
            });
          } catch (e) {
            setError(e);
          }
        }}
      >
        <label className="block mb-2">
          <span className="font-medium text-sm mb-1 block">Име</span>
          <Input
            block
            onChange={e => setName(e.target.value)}
            placeholder="Име Фамилия"
            name="name"
            required
            autoComplete="name"
          />
        </label>

        <label className="block mb-2">
          <span className="font-medium text-sm mb-1 block">Email</span>
          <Input
            block
            onChange={e => setEmail(e.target.value)}
            placeholder="email@domain.com"
            name="email"
            required
            autoComplete="email"
          />
        </label>

        <label className="relative block mb-2">
          <span className="font-medium text-sm mb-1 block">Парола</span>
          <Input
            block
            onChange={e => setPassword(e.target.value)}
            placeholder="••••••••"
            name="password"
            type="password"
            autoComplete="new-password"
            required
          />
        </label>

        <label className="relative block">
          <span className="font-medium text-sm mb-1 block">Повтори Парола</span>
          <Input
            block
            onChange={e => setPassword2(e.target.value)}
            placeholder="••••••••"
            name="password"
            type="password"
            autoComplete="new-password"
            required
          />
        </label>

        <div className="flex justify-between items-center mt-3">
          <Button variant="primary" disabled={password !== password2}>
            Регистрация
          </Button>
        </div>

        {error && (
          <p className="text-red-700 text-sm my-2">
            Грешка:{' '}
            {error.message?.code === 11000
              ? 'Вече съществува акаунт с този email.'
              : error.code}
          </p>
        )}

        <hr className="my-6" />
        <SocialLogin />
      </form>
    </>
  );
}
